<!-- eslint-disable camelcase -->
<template>
  <div>
    <div v-if="notFound">
      <b-card class="text-center">
        <feather-icon
          size="80"
          icon="SearchIcon"
        />
        <div class="error mt-2">
          <h2 class="my-2">
            User not found
          </h2>
          <b-button
            variant="outline-primary"
            @click="() => $router.push({name: 'customer-users'})"
          >
            Back to users
          </b-button>
        </div>
      </b-card>
    </div>
    <div v-else>
      <div
        v-if="loader"
        class="text-center mt-5"
      >
        <b-spinner
          type="grow"
          label="Loading..."
        />
      </div>
      <b-row v-else>
        <b-col
          cols="12"
          md="4"
        >
          <b-card>
            <div class="contact-box center-version">
              <div class="top">
                <b-avatar
                  size="100"
                  :src="viewData.profile_picture"
                  :text="avatarText(viewData.first_name)"
                  class="mb-2"
                />
                <h3 class="mb-2">
                  <strong> {{ viewData.name }} </strong>
                </h3>
                <h3
                  v-if="viewData.balance"
                  class="font-bold"
                >
                  Balance {{ viewData.balance }} £
                </h3>
              </div>
              <div class="user-info">
                <ul>
                  <li>
                    <feather-icon
                      icon="MailIcon"
                    /> Email: {{ viewData.email }}
                  </li>
                  <li v-if="viewData.gender">
                    <feather-icon
                      icon="UsersIcon"
                    /> Gender: {{ viewData.gender }}
                  </li>
                  <li>
                    <feather-icon
                      icon="ServerIcon"
                    /> Role: {{ resolveRoleName(viewData.role) }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> Phone: {{ viewData.phone }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> Birthdate: {{ viewData.birth_date || 'Empty' }}
                  </li>
                  <li>
                    <feather-icon
                      icon="PhoneIcon"
                    /> National ID: {{ viewData.national_id || 'Empty' }}
                  </li>
                  <li>
                    <feather-icon
                      icon="ActivityIcon"
                    /> Status:

                    <b-badge
                      class="change-status"
                      :variant="changeVaraint(viewData.active_type)"
                      @click="changeActiveType(viewData)"
                    >
                      {{ viewData.active_type }}
                    </b-badge>
                  </li>
                </ul>
              </div>
            </div>
          </b-card>
          <b-card>
            <div class="d-flex justify-content-between">
              <b-button
                variant=""
                :disabled="!viewData.user_previous_id"
                pill
                :to="{ path: `/users/customer/view/${viewData.user_previous_id}` }"
                @click="getdata"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="13"
                />
                Previous User
              </b-button>
              <b-button
                variant="primary"
                :disabled="!viewData.user_next_id"
                pill
                :to="{ path: `/users/customer/view/${viewData.user_next_id}` }"
                @click="getdata"
              >
                Next User
                <feather-icon
                  icon="ArrowRightIcon"
                  size="13"
                />
              </b-button>
            </div>
          </b-card>
        </b-col>
        <meta-data
          :user-address="userAddresses"
          :user-files="userFiles"
          :options="options"
          add-component-name="add-address-customer"
          edit-component-name="edit-address-customer"
        />
        <!-- userACtiveType Modal -->
        <div>
          <b-modal
            id="userACtiveType"
            ref="active-type-modal"
            hide-footer
            centered
            title="Change Active Type Status"
          >
            <b-row>
              <b-col
                cols="12"
              >
                <b-form-group label="Activation Types">
                  <b-form-select
                    v-model="selected_active_type"
                    value-field="value"
                    text-field="text"
                    :options="activeType"
                    right
                  >
                    <template #first>
                      <b-form-select-option
                        disabled
                        :value="userForm.active_type"
                      >
                        {{ userForm.active_type }}
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col
                v-if="selected_active_type === 'Rejected'"
                cols="12"
              >
                <b-form-group label="Rejection Reason">
                  <b-form-textarea
                    v-model="userForm.rejection_reason"
                    type="text"
                    rows="1"
                    placeholder="Type reason here"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <b-button
                  v-if="!loader"
                  variant="primary"
                  class="mr-1"
                  :disabled="disableUpdateStatusBtn"
                  @click.prevent="changeUserType()"
                >
                  Save
                </b-button>
                <b-button
                  variant="outline-danger"
                  class="closeModal"
                  @click="$bvModal.hide('userACtiveType')"
                >
                  Cancel
                </b-button>
                <b-button
                  v-if="loader"
                  variant="primary"
                  disabled
                  class="mr-1"
                >
                  <b-spinner
                    small
                    type="grow"
                  />
                  Loading...
                </b-button>
              </b-col>
            </b-row>
          </b-modal>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { avatarText } from '@core/utils/filter'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import user from '../users'
import MetaData from '@/components/MetaData.vue'
import handleFormData from '@/global-compositions/handleFormData'

export default {
  components: { MetaData },
  setup() {
    const {
      viewData, resolveRoleName, resolveActiveUser, userForm,
    } = user()
    const { formData, setFormData } = handleFormData()

    const userAddresses = ref([])
    const userFiles = ref([])
    const loader = ref(false)
    const notFound = ref(false)
    // eslint-disable-next-line camelcase
    const selected_active_type = ref('')

    const options = [
      { value: 'national_id', name: 'National ID' },
    ]

    const activeType = [
      { text: 'Accepted', value: 'Accepted' },
      { text: 'Rejected', value: 'Rejected' },
      { text: 'Pending', value: 'Pending' },
      { text: 'Hold', value: 'Hold' },
    ]

    return {
      viewData,
      resolveRoleName,
      resolveActiveUser,
      userAddresses,
      userFiles,
      options,
      avatarText,
      loader,
      notFound,
      activeType,
      userForm,
      selected_active_type,
      types: {},
      formData,
      setFormData,
    }
  },
  computed: {
    disableUpdateStatusBtn() {
      return this.selected_active_type === 'Rejected' && !this.userForm.rejection_reason
    },
  },
  created() {
    this.getdata()
  },
  methods: {
    filterTypes(type) {
      const filteredType = this.activeType.filter(item => item.text !== type)
      this.types = filteredType
    },
    changeActiveType(data) {
      this.userForm = data
      // this.filterTypes(data.active_type)
      this.selected_active_type = this.viewData.active_type
      this.$bvModal.show('userACtiveType')
    },
    changeVaraint(type) {
      // eslint-disable-next-line eqeqeq
      if (type == 'Accepted') return 'success'
      // eslint-disable-next-line eqeqeq
      if (type == 'Pending') return 'info'
      // eslint-disable-next-line eqeqeq
      if (type == 'Hold') return 'warning'

      return 'danger'
    },
    getdata() {
      this.loader = true
      axios.get(`users/${this.$route.params.id}`).then(res => {
        if (res.status === 200 || res.status === 201) {
          this.viewData = res.data?.data
          this.userAddresses = res.data?.data.address
          this.userFiles = res.data?.data.files
        }
      }).catch(error => {
        if ((error.response.status === 400) || error.response.status === 404) {
          this.notFound = true
        }
      }).finally(() => {
        this.loader = false
      })
    },
    changeUserType() {
      this.loader = true
      this.userForm.active_type = this.selected_active_type
      this.setFormData(this.userForm)
      this.formData.append('_method', 'PUT')
      axios.post(`users/${this.userForm.id}`, this.formData).then(res => {
        if (res.status === 200) {
          this.$bvModal.hide('userACtiveType')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Updated Succesfully',
              icon: 'BellIcon',
              variant: 'success',
            },
          })
        }
      }).finally(() => {
        this.loader = false
      })
    },
  },
}
</script>

<style lang="scss">
/* CONTACTS */
.contact-box {
  padding: 20px;
  margin-bottom: 20px;
}
.contact-box > a {
  color: inherit;
}
.contact-box.center-version {
  padding: 0;
}
.contact-box.center-version > .top {
  display: block;
  padding: 5px;
  text-align: center;
}
.contact-box.center-version > .top img {
  width: 80px;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.contact-box.center-version address {
  margin-bottom: 0;
}
.contact-box .contact-box-footer {
  border-top: 1px solid #e7eaec;
  padding: 15px 20px;
}
a{
    text-decoration:none !important;
}

.user-info ul {
  list-style: none;
  li {
    padding-top: 1rem;
  }
}
.change-status {
  cursor: pointer;
}
</style>
